<template>
    <div>
        <div class="smart-body container-fluid">
            <div class="smart-body-title">サービス概要</div>
            <span>01</span>
            <div class="smart-body-desc">
                <div style="max-width: 540px;">
                <div class="smart-body-block">
                    <div class="smart-1-img-container">
                        <div class="smart-body-desc-title">業務仕組む</div>
                        <img src="@/assets/images/car/img_car1.png" class="smart-body-img-1" alt="...">
                    </div>
                    <div class="smart-body-desc-container-1">
                        <p class="smart-body-desc-title-1">
                            サービス概要
                        </p>
                        <p class="smart-body-desc-desc-1">
                            専用端末や携帯を利用して、車両の位置情報をリアルタイムで収集し、クラウド上で管理・分析する車両監視システムです。スマートフォまたはPCで車両位置を監視できます。車両軌跡の照会と作業レポートの機能も備えています。
                        </p>
                         <p style="margin-top:40px;" class="smart-body-desc-title-1">
                            特徴
                        </p>
                        <p class="smart-body-desc-desc-1">
                            <ul>
                                <li>複数地図を対応しています。GoogleMap、OpenStreetMap、Baidu地図など。</li>
                                <li>高性能の仕組みを利用して、秒単位のデータを収集可能です。</li>
                                <li>AIエンジンを導入しています、ルート分析が可能です。</li>
                            </ul>
                        </p>
                    </div>
                </div>
                </div>
            </div>
        </div>
         <div class="smart-body container-fluid" style="background:#FFF;">
            <div class="smart-body-title">機能説明</div>
            <span>02</span>
            <div class="smart-body-desc">
               <div class="smart-body-block-2">
                   <div>
                       <div @click="tvHandler(item,index)" :class="selectedTv===index?'tv-button-selected':''" class="tv-button" v-for="(item, index) in tvData" :key="index">
                           <div class="tv-button-title">{{item.title}}</div>
                           <div class="tv-button-desc">{{item.desc}}</div>
                       </div>
                   </div>
                   <div class="tv-container">
                       <img class="screen" :src="sectedTvImage" alt="">
                       <img class="mac" src="@/assets/images/car/img_mac.png" alt="">
                   </div>
               </div>
            </div>
        </div>
        <div class="smart-body container-fluid" style="padding:0;">
            <div class="smart-body-title">車の共有</div>
            <span>03</span>
            <div class="smart-body-desc-3">
                <div>
                <div style="display:flex;align-items:center;">
                    <div class="smart-body-desc-container-3">
                        <p class="smart-body-desc-title-1">
                            サービス概要
                        </p>
                        <p class="smart-body-desc-desc-1">
                            専用端末や携帯を利用して、車両の位置情報をリアルタイムで収集し、クラウド上で管理・分析する車両監視システムです。スマートフォまたはPCで車両位置を監視できます。車両軌跡の照会と作業レポートの機能も備えています。
                        </p>
                         <p style="margin-top:40px;" class="smart-body-desc-title-1">
                            特徴
                        </p>
                        <p class="smart-body-desc-desc-1">
                            <ul>
                                <li>複数地図を対応しています。GoogleMap、OpenStreetMap、Baidu地図など。</li>
                                <li>高性能の仕組みを利用して、秒単位のデータを収集可能です。</li>
                                <li>AIエンジンを導入しています、ルート分析が可能です。</li>
                            </ul>
                        </p>
                    </div>
                    <div class="smart-body-3-img-container">
                        <div class="smart-body-desc-title">業務仕組む</div>
                        <img src="@/assets/images/car/img_car2.png" class="smart-body-img-1" alt="...">
                    </div>
                </div>
                </div>
            </div>
        </div>
        <div class="smart-body container-fluid smart-body-4">
            <div class="smart-body-title" style="color:#FFF">自動車共有機能説明</div>
            <span style="color:rgba(255,255,255,0.6)">04</span>
            <div class="smart-body-desc-4">
                <div>
                <div style="display:flex;align-items:center;">
                    <div>
                        <div class="smart-body-desc-4-title">1.会員登録</div>
                        <div class="smart-body-desc-4-text">各会員情報、免許証、車情報、車検証、保険証を登録します。</div>
                        <div class="smart-body-desc-4-title">2.車両紹介</div>
                        <div class="smart-body-desc-4-text">条件・距離で車を検索し、表示します。</div>
                        <div class="smart-body-desc-4-title">3.車両シェア</div>
                        <div class="smart-body-desc-4-text">指定車を予約して交換します。</div>
                        <div class="smart-body-desc-4-title">4.管理機能</div>
                        <div class="smart-body-desc-4-text">a.審査機能：管理者が会員の申請情報を審査して承認または拒否を行います。</div>
                        <div class="smart-body-desc-4-text">b.照会機能：車両情報・シェア履歴を照会します。</div>
                        <div class="smart-body-desc-4-text">c.コンテンツ管理：ニュースやコンテンツを管理します。</div>
                    </div>
                    <div class="smart-body-4-img-container">
                        <img src="@/assets/images/car/img_car3.png" class="smart-body-img-4" alt="...">
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            selectedTv:0,
            sectedTvImage:'',
            tvData:[
                {
                    index:0,
                    title:'1.全車両監視',
                    desc:'全車両の位置をリアルタイムに監視でき、過去の時刻を指定して過去時点の全車両位置を監視できます。',
                    imageUrl:require('@/assets/images/car/img_gongneng1.png')
                },
                {
                    index:1,
                    title:'2.車両追跡',
                    desc:'指定時間帯で収集した車両軌跡を表示し再生できます。',
                    imageUrl:require('@/assets/images/car/img_gongneng2.png')
                },
                 {
                    index:2,
                    title:'3.車両状態',
                    desc:'稼働車両の状況をグラフや表で表示します。',
                    imageUrl:require('@/assets/images/car/img_gongneng3.png')
                },
                 {
                    index:3,
                    title:'4.日報',
                    desc:'日次処理で日報を作成します。',
                    imageUrl:require('@/assets/images/car/img_gongneng4.png')
                },
                {
                    title:'5.管理機能',
                    desc:'車両・組織・運転手情報を管理します。',
                    imageUrl:require('@/assets/images/car/img_gongneng5.png')
                },
            ]
        }
    },
    mounted() {
        this.tvHandler(this.tvData[0],0)
    },
    methods: {
        tvHandler(item,index){
            this.selectedTv = index
            this.sectedTvImage = item.imageUrl
        }
    },
}
</script>

<style>
.smart-body{
    width: 100vw;
    overflow-x: hidden !important;
}
@media screen and (min-width:768px) and (max-width:1920px) {
    .smart-body{
        background-color: #F5F5F9;
        padding: 0 calc(100vw/1920*360);
        padding-bottom: 40px;
        z-index: 1;
    }
    .smart-body-block-2{
        display: flex;
    }
    .smart-body-3-img-container{
        margin-left:-120px;
        width:calc(100vw/1920*650);
        height:calc(100vw/1920*420);
        background-color:#FFF;
        z-index:0
    }
    .tv-container{
        margin-left:47px;
    }
    .smart-body-title{
        font-size:calc(100vw/1920*24);
        padding-top: 45px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:700;
        color:rgba(2,98,255,1);
        line-height:33px;
    }
     .smart-body-block{
        display:flex;
        align-items:center;
    }
    .smart-body>span{
        width:89px;
        height:89px;
        font-size:80px;
        font-family:Arial-BoldMT,Arial;
        font-weight:normal;
        color:rgba(2,98,255,1);
        line-height:92px;
        position: relative;
        /* right: calc(100vw/1920*200); */
        bottom: 60px;
        opacity: 0.1;
    }
    .smart-body-img-1{
        width: calc(100vw/1920*624);
        height: calc(100vw/1920*181);
        margin-top: calc(100vw/1920*64);
    }
    .smart-1-img-container{
         width:calc(100vw/1920*650);
         height:calc(100vw/1920*420);
         background-color:#FFF;
         z-index:2;
         background:rgba(255,255,255,1);
        box-shadow:0px 0px 10px 0px rgba(0,0,0,0.08);
    }
    .smart-body-desc-container-1{
        position:absolute;
        right:0;
        min-width: calc(100vw/1920*1050);
        height: calc(100vw/1920*500);
        background-color: #0262FF;
        margin-left: calc(-100vw/1920*140);
        padding-left: calc(100vw/1920*160);
        padding-top: 50px;
    }
    .smart-body-desc-container-1 *{
        text-align:left;
        color:#FFF;
        list-style: square outside;
        width:calc(100vw/1920*528);
    }
    .smart-body-desc-container-1 ul{
        padding:0 0 0 20px;
    }
    .smart-body-desc-title-1{
        font-size:calc(100vw/1920*18);
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:700;
        color:rgba(255,255,255,1);
        line-height:calc(100vw/1920*25);
    }
    .smart-body-desc-desc-1{
        font-size:calc(100vw/1920*16);
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:400;
        color:rgba(255,255,255,1);
        line-height:calc(100vw/1920*32);
    }
    .smart-body-desc-title{
        font-size:calc(100vw/1920*18);;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:700;
        color:rgba(51,51,51,1);
        line-height:calc(100vw/1920*25);
        padding-left: 20px;
        text-align: left;
        margin-top: 36px;
    }
    .smart-body-desc-3{
        /* padding-left: calc(100vw/1920*360); */
    }
    .tv-button{
        cursor: pointer;
        width: calc(100vw/1920*440);
        background:rgba(2,98,255,0.06);
        border-radius:8px;
        margin-bottom:calc(100vw/1920*20);
        padding: calc(100vw/1920*18) calc(100vw/1920*10) calc(100vw/1920*17) calc(100vw/1920*22.5);
    }
    .tv-button-title{
        font-size:calc(100vw/1920*16);
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:700;
        color:rgba(51,51,51,1);
        line-height:calc(100vw/1920*22);
        text-align: left;
    }
    .tv-button-desc{
        margin-top: calc(100vw/1920*10);
        font-size:calc(100vw/1920*16);
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:400;
        color:rgba(51,51,51,1);
        line-height:calc(100vw/1920*32);
        text-align: left;
    }
    .mac{
        width: calc(100vw/1920*690);
    }
    .tv-button-selected{
        background: #0262FF;
        transition: all 0.5s;
    }
    .tv-button-selected div{
        color: #FFF;
        transition: all 0.5s;
    }
    .screen{
        width: calc(100vw/1920*632);
        height: calc(100vw/1920*356);
        position: absolute;
        margin: calc(100vw/1920*30);
    }
    .smart-body-desc-container-3{
        min-width: calc(100vw/1920*1200);
        /* height: calc(100vw/1920*500); */
        background-color: #0262FF;
        margin-left: calc(-100vw/1920*140);
        padding-left: calc(100vw/1920*440);
        padding-top: 50px;
    }
    .smart-body-desc-container-3 *{
        text-align:left;
        color:#FFF;
        list-style: square outside;
        width:calc(100vw/1920*528);
    }
    .smart-body-desc-container-3 ul{
        padding:0 0 0 20px;
    }
    .smart-body-desc-4-title{
        color: #FFF;
        width: calc(100vw/1920*560);
        text-align: left;
        margin-top: 40px;
    }
    .smart-body-desc-4-text{
        width: calc(100vw/1920*560);
        font-size:calc(100vw/1920*16);
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:400;
        color:rgba(255,255,255,1);
        line-height:calc(100vw/1920*22);
        opacity: 0.4;
        text-align: left;
        margin-top: 20px;
    }
    .smart-body-img-4{
        position: relative;
        top: 100px;
    }
    .smart-body-4{
        background:#1B2144;
        margin-bottom:120px;
    }
}
@media screen and (max-width:768px) {
    .smart-body{
        background-color: #F5F5F9;
        padding: 0 20px;
        padding-bottom: 40px;
        z-index: 1;
    }
    .smart-body-title{
        font-size:12px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:700;
        padding-top: 20px;
        color:rgba(2,98,255,1);
        line-height:33px;
    }
    .smart-body-block{
        display:flex;
        flex-direction: column;
        align-items:center;
    }
    .smart-body-block-2{
        display: flex;
        flex-direction: column-reverse;
    }
    .smart-body>span{
        width:89px;
        height:89px;
        font-size:40px;
        font-family:Arial-BoldMT,Arial;
        font-weight:normal;
        color:rgba(2,98,255,1);
        line-height:92px;
        position: relative;
        /* right: calc(100vw/1920*200); */
        bottom: 60px;
        opacity: 0.1;
    }
    .smart-body-img-1{
        width: 87vw;
    }
    .smart-body-desc-container-1{
        /* position:absolute; */
        padding: 20px;
        background-color: #0262FF;
        padding-top: 20px;
    }
    .smart-body-desc-container-1 *{
        text-align:left;
        color:#FFF;
        list-style: square outside;
        /* width:200px; */
    }
    .smart-1-img-container{
        /* width:100px; */
        /* height:300px; */
        width:100%;
        background-color:#FFF;
        z-index:2;
        background:rgba(255,255,255,1);
        box-shadow:0px 0px 10px 0px rgba(0,0,0,0.08);
    }
    .smart-body-desc-container-1 ul{
        padding:0 20px 0 20px;
    }
    .smart-body-desc-title-1{
        font-size:12px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:700;
        color:rgba(255,255,255,1);
        /* line-height:14px; */
    }
    .smart-body-desc-desc-1{
        font-size:12px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:400;
        color:rgba(255,255,255,1);
        /* line-height:14px; */
    }
    .smart-body-desc-title{
        font-size:12px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:700;
        color:rgba(51,51,51,1);
        line-height:14px;
        margin-bottom: 50px;
        padding-left: 20px;
        text-align: left;
        margin-top: 36px;
    }
    .smart-body-desc-3{
        /* padding-left: calc(100vw/1920*360); */
    }
    .tv-button{
        cursor: pointer;
        background:rgba(2,98,255,0.06);
        border-radius:8px;
        margin: 20px 0;
        padding: 20px;
    }
    .tv-button-title{
        font-size:13px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:700;
        color:rgba(51,51,51,1);
        line-height:14px;
        text-align: left;
    }
    .tv-button-desc{
        margin-top: 5px;
        font-size:12px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:400;
        color:rgba(51,51,51,1);
        line-height:14px;
        text-align: left;
    }
    .mac{
        width: 90vw;
    }
    .tv-button-selected{
        background: #0262FF;
        transition: all 0.5s;
    }
    .tv-button-selected div{
        color: #FFF;
        transition: all 0.5s;
    }
    .screen{
        width: 83vw;
        position: absolute;
        margin: -40px 13px;
    }
    .smart-body-desc-container-3{
        min-width: 200px;
        background-color: #0262FF;
        padding: 20px;
    }
    .smart-body-desc-container-3 *{
        text-align:left;
        color:#FFF;
        list-style: square outside;
        width:100%;
    }
    .smart-body-desc-container-3 ul{
        padding:0 0 0 20px;
    }
    .tv-container{
        display: flex;
        align-items: center;
        width: 200px;
    }
    .smart-body-3-img-container{
        display: none;
        margin-left:-120px;
        width:100px;
        height:calc(100vw/1920*420);
        background-color:#FFF;
        z-index:0
    }
    .smart-body-desc-4-title{
        color: #FFF;
        width: calc(100vw/1920*560);
        text-align: left;
        margin-top: 40px;
    }
    .smart-body-desc-4-text{
        width: 300px;
        font-size:12px;
        font-family:Noto Serif JP,YuGothic,serif;
        font-weight:400;
        color:rgba(255,255,255,1);
        line-height:14px;
        opacity: 0.4;
        text-align: left;
        margin-top: 20px;
    }
    .smart-body-img-4{
        width: 250px;
        position: relative;
        top: -250px;
        right: 250px;
    }
    .smart-body-4{
        background:#1B2144;
        /* margin-bottom:120px; */
    }
}
</style>